import React from 'react';
import { Container } from 'react-bootstrap';

import users from '@/assets/icons/users.svg';
import clinic from '@/assets/icons/clinica.svg';
import star from '@/assets/icons/star-gold.svg';
import tasks from '@/assets/icons/tasks.svg';
import trophy from '@/assets/icons/trophy.svg';
import * as styles from './numbers.module.scss';

const Numbers = () => {
  return (
    <Container>
      <div className={styles.container}>
        <div className={styles.wrapper}>
          <div className={styles.item}>
            <img src={users} alt="usuários ativos" className={styles.image} loading="lazy" />
            <div>
              <span className={styles.symbol}>+</span>
              <span className={styles.number}>80 mil</span>
            </div>
            <p className={styles.text}>usuários ativos</p>
          </div>

          <div className={styles.item}>
            <img
              src={clinic}
              alt="clinicas e consultórios"
              className={styles.image}
              loading="lazy"
            />
            <div>
              <span className={styles.symbol}>+</span>
              <span className={styles.number}>12 mil</span>
            </div>
            <p className={styles.text}>clínicas e consultórios</p>
          </div>

          <div className={styles.item}>
            <img src={star} alt="melhor nota em apps" className={styles.image} loading="lazy" />
            <div>
              <span className={styles.highlight}>4,9</span>
              <span className={styles.highlightSmall}> de </span>
              <span className={styles.highlight}>5</span>
            </div>
            <p className={styles.textHighlight}>melhor nota em apps</p>
          </div>

          <div className={styles.item}>
            <img src={tasks} alt="pacientes cadastrados" className={styles.image} loading="lazy" />
            <div>
              <span className={styles.symbol}>+</span>
              <span className={styles.number}>40 milhões</span>
            </div>
            <p className={styles.text}>pacientes cadastrados</p>
          </div>

          <div className={styles.item}>
            <img src={trophy} alt="líder de mercado" className={styles.image} loading="lazy" />
            <span className={styles.number}>O Simples Dental</span>
            <p className={styles.text}>é líder de mercado</p>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default Numbers;
